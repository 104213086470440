@layer frameworks, defaults, overrides;

@layer defaults {
    .title-kp-preview {
        padding: var(--container-padding);
        background-color: var(--color-white);
        display: flex;
        justify-content: space-between;
    }

    .title-kp-preview__data {
        font-weight: 300;
        font-size: 20px;
    }

    .title-kp-preview__data-second {
        font-weight: 400;
        font-size: 12px;
    }

    .title-kp-preview__name {
        font-size: 24px;
        font-weight: 800;
        color: var(--color-blue);
    }
}

