.status-info-table__wrapper{
    padding: 20px;
    width: 80vw;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
}
.status-info-table__container{
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
}
.status-info-table__btns{
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}