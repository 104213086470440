.file-renderer__dropzone{
    border: 2px darkgray dashed;
    border-radius: 1rem;
    padding: 1em;
    width: fit-content;
}
.file-renderer__image{
    width: 100px;
    height: 100px;
    object-fit: contain;
}
.file-renderer__title{
    font-size: 14px;
}

.file-renderer__image_stack{
    position: relative;
}
.file-renderer__image_mask{
    display: flex;
    align-items: flex-start;
    justify-content: space-around;

    padding: 1rem;

    position: absolute;
    background: var(--color-light-gray);
    opacity: 0;
    width: 100%;
    height: 100%;
    transition: all 0.25s linear;
}
.file-renderer__image_mask:hover{
    opacity: 0.4;
}
.file-renderer__image_btn{
    opacity: 1;
}
.carousel__add_container{
    background: var(--color-light-gray);
}