.statuses-list__wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
}
.statuses-list__header{
    gap: 10px;
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.statuses-list__items{
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
}
.button-extension{
    width: 250px;
    height: 35px;
}