@import '../../../../assets/layers.css';

@layer overrides {
    .additional-materials-tkp__field {
        width: 60px;
    }
}

.slide-tkp-body__wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.slide-tkp__equipments{
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 65px);
}
.slide-tkp__table{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: calc(100% - 35px);
    overflow: auto;
}
.slide-tkp__stripe_info{
    border-radius: 6px;
    background: #CCCCCC;
    color: black;
    font-size: 13px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 10px;
    width: calc(100% - 20px);
    height: 25px;
}
.slide-tkp-price__container{
    border-radius: 8px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 65px;
    background: #009Fe3;
}
.slide-tkp-price__material{
    width: calc(100% - 40px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0 20px;
    font-weight: 600;
    font-size: 13px;
    color: #F5F5F5;
    gap: 10px;
}
.slide-tkp-price__separator {
    width: 2px;
    height: 100%;
    background: black;
    opacity: 0.2;
    border-radius: 5px;
}
.slide-tkp-price__item {
    text-align: center;
}
.slide-tkp-price__item p {
    margin: 0;
}
.slide-tkp-price__span_in-price {
    color: #00fb3b;
}
.slide-tkp-price__span_out-price {
    color: #fb7378;
}