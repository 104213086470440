@import "../../../assets/layers.css";

@layer defaults {
    .table__head{
        scrollbar-gutter: stable;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        max-height: 40px;
        padding: 0 5px;
        width: calc(100% - 10px);

        background: #FCFCFD;

        border-bottom: 1px solid rgba(234, 236, 240, 1);


        font-size: 14px;
        color: #6F767E;
        font-weight: 600;
        text-align: center;
    }
    .table__head .table__row{
        background: #FCFCFD;
        border-bottom: none;

    }
    @media screen and (max-width: 1440px) {
        .table__head{
            font-size: 13px;
        }
    }
    @media screen and (max-width: 1280px) {
        .table__head{
            font-size: 12px;
        }
    }
    @media screen and (max-width: 1024px) {
        .table__head{
            font-size: 10px;
        }
    }
}
