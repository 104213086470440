/*@import "../../../index.css" layer(defaults);*/
/*@layer frameworks, defaults;*/

@layer defaults {
    .table__body{
        scrollbar-gutter: stable;
        overflow: auto;
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: minmax(40px, 40px);
        height: calc(100% - 40px);
        width: 100%;
    }
    @media screen and (max-width: 1440px){
        .table__body{
            grid-auto-rows: minmax(40px, 40px);
        }
    }
    @media screen and (max-width: 1280px){
        .table__body{
            grid-auto-rows: minmax(35px, 35px);
        }
    }
    @media screen and (max-width: 1024px){
        .table__body{
            grid-auto-rows: minmax(25px, 25px);
        }
    }
}


