.kp-detail {
    position: relative;
    --gap-nav: 15px;
    width: 100%;
    height: 100%;
    overflow: auto;
}

/*INFO*/
.kp-detail__info {
    --item-size: 65px;
    --gap-column: 17px;
    width: 100%;
    display: flex;
    gap: 23px;
    margin-top: 35px;
}

.kp-detail__column {
    flex: 1 0 calc(50% - 20px);
    display: flex;
    gap: var(--gap-column);
    flex-direction: column;
}

/*SUBMIT BUTTON*/
.kp-detail__submit {
    flex: 0 0;
    background-color: var(--color-blue);
    color: var(--color-white);
    border: none;
}

.kp-detail__controls {
    margin-top: 25px;
    display: flex;
    gap: 25px;
    justify-content: space-between;
    align-items: center;
}

.kp-detail__prices {
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between;
    font-weight: 600;
    font-size: 13px;
    color: var(--color-white);
    gap: 2px;
    background: #007fb5;
    border-radius: 0.37rem;
    overflow: hidden;
}

.kp-detail__prices-item {
    height: 40px;
    padding: 0 10px;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: center;
    background: #009FE3;
    text-align: center;
    white-space: nowrap;
    border-radius: 0.37rem;
}

/*ADD PRODUCT BUTTON*/
.kp-detail__add-btn {
    background-color: var(--color-blue);
    color: var(--color-white);
    border: none;
    display: flex;
    align-items: center;
    gap: 10px;
}

.kp-detail__add-icon {
    transform: rotate(45deg);
    width: 20px;
    height: 20px;
}

.kp-detail__table-controls {
    margin: 25px 0 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/*TABLE*/
.kp-detail__items-wrapper {
    position: relative;
    width: 100%;
}

.kp-detail__items {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.kp-detail__item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    font-size: 14px;
    text-align: center;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.kp-detail__item_name {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
}

.kp-detail__item_type {
    flex: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.kp-detail__item_quantity {
    flex: 0.3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.kp-detail__item_unit {
    flex: 0.3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.kp-detail__item_price {
    flex: 0.3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.kp-detail__item_discount {
    flex: 0.3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.kp-detail__item_total {
    flex: 0.3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.kp-detail__item_action {
    flex: 0.2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.kp-detail__item_name_head {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.kp-detail__form {
    display: flex;
    flex: 1 1;
    align-items: center;
    gap: 10px;
}
.kp-detail__form-label {
    margin-bottom: 0;
}
.kp-detail__switch {
    white-space: nowrap;
    display: flex;
    gap: 5px;
}
.kp-detail__switch > .form-check-input:checked {
    border-color: var(--color-blue);
    background-color: var(--color-blue);
}
.kp-detail__input {
    max-width: 300px;
    width: 100%;
    min-width: fit-content;
    border: 1px solid var(--color-gray);
    border-radius: 4px;
    padding: 6px;
}
.button-extension-kp {
    width: 100px;
    height: 25px;
}

.kp__preview-modal {
    width: 85vw;
    height: 85vh;
}