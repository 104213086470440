.guids-suppliers-list__wrapper{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 20px;
}
.guids-suppliers__header{
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    flex: 0.5;
}
.guids-suppliers-table__container{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    flex: 15;
}
.guids-suppliers__footer{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    flex: 1;
}
.guids-suppliers__btn_add{
    border: none;
    background: #00b4e6;
    height: 40px;
    width: 200px;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    color: white;
    transition: all 0.15s linear;
}
.guids-suppliers__btn_add:active{
    transform: scale(0.98);
}