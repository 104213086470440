@layer defaults {
    .tab-button-remains {
        --padding-tab-btn: 25px;
        background-color: transparent;
        border: none;
        display: flex;
        align-items: center;
        gap: 5px;
        width: calc(100% - var(--padding-tab-btn) * 2);
        min-width: min-content;
        height: 35px;
        padding: 0 var(--padding-tab-btn);
        border-radius: 20px;
        white-space: nowrap;
    }

    .tab-button-remains_active {
        border: 2px solid var(--color-light-blue);
    }

    .tab-button-remains__icon {
        width: 25px;
        height: 25px;
    }
}