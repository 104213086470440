.message-hub__wrapper{
    position: fixed;
    z-index: 1000;
    width: auto;
    bottom: 30px;
    margin: 0 auto;
    left: 30px;
    right: 30px;
    display: flex;
    gap: 20px;
    flex-direction: column;
    pointer-events: none;
    align-items: flex-end;
}
.message-hub__item{
    box-sizing: border-box;
    position: relative;
    width: 40ch;

}
.message-hub__content{
    position: relative;
    color: var(--color-white);
    background: var(--color-blue);
    opacity: 0.9;
    padding: 12px 22px;
    font-size: 1em;
    display: flex;
    overflow: hidden;
    height: auto;
    border-radius: 6px;
    margin-top: 10px;
    flex-direction: column;
}
.message-hub__life{
    position: absolute;
    bottom: 0;
    left: 0;
    width: auto;
    background-image: linear-gradient(130deg, #00b4e6, #00f0e0);
    height: 5px;
}
.message-hub__title_container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.message-hub__text{
    font-size: 14px;
    font-weight: 500;
    margin: 5px 0;
    padding: 0;

}
.message-hub__text_notification{
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    padding: 0;
}
.message-hub__button{
    width: 10px;
    height: 10px;
    color: var(--color-white);
    cursor: pointer;
    pointer-events: all;
    outline: 0;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
}