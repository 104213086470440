.item-kp-detail {
    position: relative;
    padding: 11px 32px 11px 16px;
    width: 100%;
    height: var(--item-size);
    border-radius: 0.375rem;
    cursor: pointer;
    box-shadow: 2px 2px 4px var(--color-light-gray);
    background: white;
}

.item-large {
    height: calc(var(--item-size) * 2 + var(--gap-column));
}

@media (hover: hover) {
    .item-kp-detail:hover {
        background-color: var(--color-blue-hover);
        color: var(--color-blue);
    }
}

.item-kp-detail__title {
    position: absolute;
    top: -13px;
    color: var(--color-blue);
    z-index: 10;
}

.item-kp-detail__text {
    line-height: unset;
    font-size: 13px;
    font-weight: 400;
    color: var(--color-dark-gray);
    height: 100%;
    overflow: auto;
}
.item-kp-detail__icon_container{
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 40px;
    height: 40px;

    display: flex;
    align-items: center;
    justify-content: center;
}
.item-kp-detail__icon {
    width: 20px;
    height: 20px;
}

.item-kp-detail__textarea {
    width: 100%;
    height: 100%;
    border: none;
    resize: unset;
    background-color: transparent;
    color: var(--color-dark-gray);
    font-size: 13px;
}
.item-kp-detail__textarea:focus-visible {
    /*border: none;*/
    outline: none;
}