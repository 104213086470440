.materials {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.materials__header {
    border-radius: 0.375rem;
    background: var(--color-gray);
    color: var(--color-dark-gray);
    padding: 9px 24px;
    margin-top: 20px;
    font-weight: 600;
    line-height: 1.7;
}