@import '../../../../assets/layers.css';

@layer defaults {
    .inherit-input {
        width: inherit;
        height: inherit;
        font-size: inherit;
        font-family: inherit;
        font-weight: inherit;
        border: none;
        border-radius: inherit;
        overflow: inherit;
        overflow-x: inherit;
        overflow-y: inherit;
        outline: none;
        background: inherit;
        color: inherit;
        display: inherit;
        justify-content: inherit;
        align-items: inherit;
        text-align: inherit;
        appearance: none;
        -moz-appearance: none;
        field-sizing: content;
    }

    .inherit-input::-webkit-outer-spin-button, .inherit-input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
}
