@layer frameworks, defaults, overrides;

@layer defaults {
    .kp-preview {
        --container-padding: 25px;
    }
    
    .kp-preview__content {
        padding: var(--container-padding);
    }

    .kp-preview__title {
        text-align: center;
    }

    .kp-preview__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
    }

    .kp-preview__controls {
        display: flex;
        gap: 10px;
    }

    .kp-preview__btn {
        background-color: var(--color-blue);
    }

    .kp-preview__header-logo {
        width: 300px;
    }
}