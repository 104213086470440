@import "../../../assets/layers.css";

@layer defaults {
    .table__row{
        --sizes-columns: 1fr;
        width: 100%;
        height: 39px;
        display: grid;
        grid-template: 1fr / var(--sizes-columns);
        background: white;

        border-bottom: 1px solid rgba(234, 236, 240, 1);
    }
}

