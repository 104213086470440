:root {
    --bs-blue: #009Fe3;
    --bs-primary: #009Fe3;
    --color-green: #00fb3b;
    --color-blue: #009Fe3;
    --color-blue-hover: #cedae6;
    --color-red: #e15e5a;
    --color-light-red: #fb7378;
    --color-white: #F5F5F5;
    --color-light-gray: #d3d3d3;
    --color-gray: #CCCCCC;
    --color-dark-gray: #6F767E;
    --color-black: #000;
    --color-light-blue: #009FE3;
    --color-light-green: #89E219;
    --color-light-purple: #8E55EA;
    --color-yellow-muted: #ECB22E;
    --color-red-tomato: #D84C10;
}