.register-btn {
    padding: 6px 12px;
    background-color: var(--color-blue);
    border: none;
    border-radius: 0.375rem;
    color: var(--color-white);
}

#basic-addon2 {
    color: var(--color-dark-gray);
    display: inline-block;
    width: 50px;
    height: 39px;
}