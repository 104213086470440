.submit-button {
    position: absolute;
    top: 50%;
    left: 50%;
}

.submit-button > button {
    position: relative;
    z-index: 10;
    background: var(--color-blue);
    color: var(--color-white);
    border-radius: 5px;
    border: none;
    height: 40px;
    width: 100px;
}

.submit-button:before {
    content: '';
    position: absolute;
    z-index: 1;
    width: 30px;
    height: 30px;
    top: 50%;
    left: 0;
    background-color: var(--color-blue);
    border-radius: 5px;
    transform: translate(-40%, -50%) rotate(45deg);
}

.submit-button_inactive {
    display: none;
}