.base__wrapper{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    min-width: 70vw;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #141718;

}
.main__content{
    --offset: 24px;
    flex: 1 0 auto;
    margin: var(--offset) var(--offset) var(--offset) 0;
    border-radius: 20px;
    overflow: hidden;
    padding: 20px;
    height: calc(100% - 2 * var(--offset));
    width: calc(100% - 344px);
    border: 1px solid #E8ECEF;
    box-shadow: 0 var(--offset) 60px 0 #0000001A;
    background: #eef2f4;

}
.main__content_full{
}
