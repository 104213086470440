.clients-list__wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
}
.clients-list__header{
    gap: 10px;
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.clients-list__header_btns{
    border: 2px solid #F3F5F7;
    border-radius: 12px;
}
.clients-list__title{
    margin: 0;
    padding: 0;
    font-size: 24px;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: -0.04em;
    text-align: left;

}
.clients-list__items{
    height: calc(100% - 50px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.button-extension{
    width: 200px;
    height: 35px;
}
.clients__wrapper{
    border-radius: 20px;
    background: transparent;
    border: 1px solid rgba(234, 236, 240, 1)
}
.search__input{

}
.btns__slices{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    height: 100%;
    gap: 10px;
}