@import "../../../assets/layers.css";

@layer defaults {
    .table__wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        max-width: calc(100% - 2px);
        max-height: calc(100% - 2px);

        overflow: hidden;

        border-radius: 20px;
        background: transparent;
        border: 1px solid rgba(234, 236, 240, 1)
    }

    .table__wrapper::-webkit-scrollbar{
        width: 5px;
        border-radius: 15px;
    }
    .table__wrapper::-webkit-scrollbar-track {
        border-radius: 15px;
    }
    .table__wrapper::-webkit-scrollbar-thumb {
        background: lightgray;
        border-radius: 15px;
    }
    .table__wrapper div::-webkit-scrollbar{
        width: 5px;
        border-radius: 15px;
    }
    .table__wrapper div::-webkit-scrollbar-track {
        border-radius: 15px;
    }
    .table__wrapper div::-webkit-scrollbar-thumb {
        background: lightgray;
        border-radius: 15px;
    }
}
