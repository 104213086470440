@layer frameworks, defaults, overrides;

@layer defaults {
    .line-kp-preview {
        background-color: var(--color-blue);
        border-radius: 7px;
        color: white;
        padding: 5px;
        margin: 0 10px;
    }
}