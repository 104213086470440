/*@import "../../index.css" layer(defaults);*/

.field__button_extension{
    width: 200px;
    height: 36px;
}


@layer defaults {
    .field-block__wrapper{
        background: #f9fafb;
        width: 600px;
        padding: 10px;
        border-radius: 1rem;
    }
    .field-block__title{
        padding: 0;
        margin: 0 0 0 0;
        font-size: 13px;
    }
    .field__block{
        height: fit-content;
        width: fit-content;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 10px;
        flex-direction: column;
    }
    .field__block_icon{
        transition: all 0.3s linear;
    }
    .field__block_icon_rotate{
        transform: rotate(180deg);
    }
    .field__block_header button{
        padding: 0;
    }
    .field__title{
        margin: 0;
        padding: 0;
    }
    .accordion-button{
        padding: 0!important;
        gap: 10px;
        background: transparent!important;
        box-shadow: none!important;
    }
    .field__info{
        padding: 5px 0;
        margin-bottom: 5px;
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        gap: 5px;
    }
    .field__label{
        font-size: 12px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.02em;
        text-align: left;
        color: #a3a9b1;

    }
    .field__input{
        margin-left: 15px;
        gap: 10px;
        height: 100%;
        width: calc(100% - 15px);
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .filed__multi_input{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
    }

    .field__checkbox{

    }
    .field__date{

    }
    .field__select{
        width: 250px;
        height: 35px;
    }
    .field__text{
        max-width: 400px;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.01em;
        text-align: left;
        word-break: break-all
    }
    .field__img{
        border-radius: 50%;
        width: 100px;
        height: 100px;
    }
    .field-btns__wrapper{
        position: absolute;
        bottom: 35px;
        left: 50%;
        transform: translateX(-50%);
    }



    @media screen and (max-width: 1660px) {
        .field__title{
            font-size: 16px;
        }
        .field__label{
            font-size: 14px;
            line-height: 20px;
        }
        .field__text{
            font-size: 14px;
            line-height: 20px;
        }
    }
    @media screen and (max-width: 1440px) {
        .field__title{
            font-size: 15px;
        }
        .field__label{
            font-size: 13px;
            line-height: 20px;
        }
        .field__text{
            font-size: 13px;
            line-height: 20px;
        }
    }
    @media screen and (max-width: 1280px) {
        .field__title{
            font-size: 13px;
        }
        .field__label{
            font-size: 11px;
            line-height: 18px;
        }
        .field__text{
            font-size: 11px;
            line-height: 18px;
        }
    }
    @media screen and (max-width: 1024px) {
        .field__title{
            font-size: 12px;
        }
        .field__label{
            font-size: 10px;
            line-height: 14px;
        }
        .field__text{
            font-size: 10px;
            line-height: 14px;
        }
    }
}