@import "../../../assets/layers.css";

@layer defaults {
    
}
.table__column{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    overflow-x: hidden;

    color: rgba(102, 112, 133, 1);

    font-size: 13px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0;
    text-align: left;

}
@media screen and (max-width: 1660px){
    .table__column{
        font-size: 12px;
    }
}
@media screen and (max-width: 1440px){
    .table__column{
        font-size: 12px;
    }
}
@media screen and (max-width: 1280px){
    .table__column{
        font-size: 12px;
    }
}
@media screen and (max-width: 1024px){
    .table__column{
        font-size: 10px;
    }
}