@import '../../../../assets/layers.css';

@layer defaults {
    .submit-modal {
        --bs-modal-width: 400px;
    }
}
.submit-modal__content {
    display: flex;
    justify-content: center;
    font-size: 21px;
}
.submit-modal__controls {
    margin-top: 25px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}
.submit-modal__btn {
    border-radius: 0.375rem;
    border: none;
    background-color: var(--color-blue);
    color: var(--color-white);
    padding: 5px 13px;
    min-width: 128px;
}
.submit-modal__btn_close {
    background-color: var(--color-red);
}