.shipment-list__wrapper{
    padding: 10px;
    width: 85vw;
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
}
.shipment-list__header{
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    flex: 0.5;
}
.shipment-table__wrapper{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    flex: 15;
}
.shipment-list__btns{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 0 20px;
    width: calc(100% - 40px);
    flex: 1;
}
.shipment-list__btns_add{
    border: none;
    background: #00b4e6;
    height: 40px;
    width: 250px;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    color: white;
    transition: all 0.15s linear;
}
.shipment-list__btns_add:active{
    transform: scale(0.98);
}
.guids-table__extensions{
    overflow: visible;
}
.input_date{
    border: 2px solid #efefef;
    padding: 5px;
    border-radius: 8px;
}