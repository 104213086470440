.button-summary{
    background-color: var(--color-blue);
    border: none;
    display: flex;
    align-items: center;
}
@media (hover: hover) {
    .button-summary:hover{
        background-color: var(--color-blue-hover);
    }
}
.offcanvas-tkp__wrapper{
    width: 90%!important;
}
.tkp__container_n{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
}
.tkp-header__container{
    position: relative;
    z-index: 500;
    height: 50px;
    width: 100%;
    border-bottom: 10px solid var(--color-blue);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    padding: 5px 10px;
}
.tkp-header__info{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    gap: 10px;
}
.tkp-header__preview{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 65%;
    height: 100%;
}
.tkp-header_title{
    display: flex;
    align-items: center;
    text-align: left;

    height: 100%;
    width: 100%;

    white-space: pre;
    font-size: 18px;
    font-weight: 700;
    color: var(--color-black);
}
.tkp-header_title span{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    width: 100%;

}
.tkp-header__name_input{
    display: flex;
    align-items: center;

    height: 100%;
    width: 100%;

    border: none;
    outline: none;
    background: transparent;
    font-size: 15px;
    font-weight: 600;
    color: var(--color-dark-gray);
    position: relative;
}
.tkp-header__info_deal{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 35%;
    height: 100%;
    gap: 5px;
}

.tkp-header__preview_title{
    margin-left: 5%;
    padding: 3px 8px;
    background: var(--color-dark-gray);
    font-weight: 500;
    font-size: 11px;
    color: var(--color-white);
    border-radius: 8px;
}


.tkp-header_name::placeholder{
    display: flex;
    align-items: center;

    height: 100%;
    width: 100%;
}

.tkp-header__title_preview{
    font-size: 18px;
    font-weight: 700;
    color: var(--color-black);
}
.tkp-header__title_name{
    background: transparent;
    font-size: 15px;
    font-weight: 600;
    color: var(--color-dark-gray);
    position: relative;
}

.tkp-systems__wrapper{
    position: relative;
    z-index: 260;
    height: 100%;
    width: 100%;
    gap: 20px;
    overflow: auto;
    scrollbar-gutter: stable;
}
.tkp-system__container{
    min-height: 325px;
    width: 100%;
    padding: 0 10px;
    border-radius: 8px;
    border-left: 10px solid #00a3ff;
    background: white;
}
.tkp-system__container:not(:first-child) {
    margin-top: 20px;
}
.tkp-system__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 45px;
    font-size: 14px;
    white-space: pre;
}
.tkp-system__container_info{
    display: flex;
    align-items: center;
    gap: 10px;
    width: 60%;
    height: 100%;
}
.tkp-system__header_draggable{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    text-align: center;
    height: 100%;
}
.tkp-system__header_square{
    width: fit-content;
    text-align: center;
    background: #1C87E9;
    border-radius: 6px;
    color: white;
    padding: 2px 5px;
}
.tkp-system__header_preview{
    display: flex;
    align-content: center;
    width: 300px;
}
.tkp-system__container_selects{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    width: 40%;
    height: 100%;
}
.tkp-system__selects_item{

}
.tkp-slices__wrapper{
    overflow: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.tkp-system__footer{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    height: 95px;
}
.tkp-system__footer button{
    padding: 6px 12px;
    width: 100%;
}

.tkp-footer__wrapper{
    height: 90px;
    width: 100%;
}
.tkp-footer__container{
    gap: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.tkp-footer__btns{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 10px;
}
.tkp-slice__name{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}

@media screen and (max-width: 1440px) {

}
@media screen and (max-width: 1280px) {

}
@media screen and (max-width: 1024px) {
    .tkp-summary-table-price__material{
        font-size: 12px;
    }
    .tkp-summary-table-price__diluent{
        font-size: 12px;
    }
    .tkp-summary-table-price__total{
        font-size: 13px;
    }
    .tkp-summary-table__title_preview{
        font-size: 15px;
    }
    .tkp-summary-table__title_name{
        font-size: 15px;
    }
    .tkp-summary-table__header_name{
        font-size: 12px;
    }
    .tkp-summary-table__header_title{
        font-size: 16px;
    }
}