.system-tkp__container{
    flex: 1 0 calc(100% - 20px);
    width: calc(100% - 30px);
    border-left: 10px solid #00a3ff;
    border-radius: 8px;
    background: #F5F5F5;
    min-height: 400px;
    max-height: 500px;
    padding: 10px;
    box-shadow: 0px 9px 17px 0px rgba(34, 60, 80, 0.15);
    position: relative;



}
.system-tkp__header{
    position: relative;
    max-height: 15%;
    min-height: 15%;
    height: 15%;
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.system-tkp__container_info{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    height: 100%;
    width: calc(100% - 40px);
    gap: 10px
}
.system-tkp__header_square{
    height: 30%;
    flex: 1 0 10%;
    background: #00a3ff;
    border-radius: 8px;
    color: white;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
}
.system-tkp__header_preview{
    height: 100%;
    flex: 1 0 90%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
}
.system-tkp__header_title{
    font-size: 18px;
    font-weight: 700;
    color: black
}
.system-tkp__header_name{
    border: none;
    outline: none;
    background: transparent;
    font-size: 15px;
    font-weight: 600;
    color: #6F767E;
    position: relative;
}
.system-tkp__container_selects{
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 20px;
    height: 100%;
    width: calc(100% - 40px);
    gap: 15px;
    position: relative;
}
.system-tkp__selects_item{
    position: relative;
    display: flex;
    align-items: center;
    flex: 1 0 30%;
    height: 50%;
}
.system-tkp__selects_item:nth-last-child(1){
    flex: 1 0 40%;
}
.slices-tkp__wrapper{
    padding: 10px 0;
    height: calc(70% - 20px);
    max-height: calc(70% - 20px);
    width: 100%;
    max-width: 100%;
    position: relative;
    z-index: 300;
}
.system-client-tkp__footer{
    position: relative;
    z-index: 200;
    height: calc(15% - 10px);
    max-height: 15%;
    max-width: 100%;
    padding: 5px 0;
    width: 100%;
    overflow-y: hidden;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
}
.system-tkp__footer_item{
    width: 100%;
    flex: 1;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
}
.system-tkp__footer_text{
    font-weight: 600;
    font-size: 14px;
    color: #6F767E;
}
.system-tkp__btn_delete:active{
    transform: scale(0.99);
}
@media screen and (max-width: 1440px) {
    .system-tkp__footer_text{
        font-size: 13px;
    }
}
@media screen and (max-width: 1280px) {
    .system-tkp__footer_text{
        font-size: 12px;
    }
}
@media screen and (max-width: 1024px) {
    .system-tkp__header_name{
        font-size: 13px;
    }
    .system-tkp__header_title{
        font-size: 15px;
    }
    .system-tkp__footer_text{
        font-size: 11px;
    }
}