@font-face {
    font-family: 'Open Sans Regular';
    src: url("../../../../../assets/OpenSans-Regular.ttf");
}
@font-face {
    font-family: 'Open Sans Bold';
    src: url("../../../../../assets/OpenSans-Regular.ttf");
}
@page {
    size: A3 landscape;
    margin: 0;



}
.tkp-client__logo {
    width: 300px;
}
.content{
    font-family: "Open Sans Regular";
    background: #F5F5F5;
    padding: 25px;
    width: calc(100% - 50px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.content h2 {
    padding: 0;
    height: 2%;
}

.content-child{
    font-family: "Open Sans Regular";
    background: #F5F5F5;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}
.systems__wrapper{
    background: white!important;
    font-family: "Open Sans Regular";
    padding: 25px;
    width: calc(100% - 50px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.content h2 {
    padding: 0;
    margin: 0;
}
.content-child_systems{
    height: fit-content;
    width: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 25px;
    page-break-after: always;
    break-after: page;
}
.content__header{
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 35px 0 0;
    gap: 20px;
}
.title-content {
    width: 100%;
    margin-bottom: 25px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}
.title-content__header {
    font-size: 24px;
    font-weight: 800;
    color: #00a3ff;
}
.title-content__name {
    font-size: 20px;
    font-weight: 300;
}
.title-content__name-ship {
    font-size: 20px;
    font-weight: 300;
}
.info-title-content__creator {
    font-size: 12px;
}
.info-title-content__date {
    font-size: 12px;
    font-weight: 400;
}
.text__header{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    height: 100%;
    margin: 0 0 0 30px;
    flex: 3;
    overflow-x: hidden;

}
.btns__header{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    height: 100%;
    flex: 1
}
.btn-download{
    background: white;
    border: 2px solid rgba(0, 0, 0, 0.3);
    color: grey;
    padding: 5px 10px;
    border-radius: 8px;
    transition: all 0.1s linear;


}
.btn:active{
    transform: scale(0.98);
}
.before{
    page-break-before: always;
}
.after{
    page-break-after: always;
}
.avoid{
    page-break-inside: avoid;
}
.title_text{

}
.date_text{
    color: grey;
}
.content__body{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    flex: 8;
    width: 100%;
    gap: 10px;
}
.content__footer{
    flex: 0;
    width: 100%;
}
.table__content{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40%;
    overflow-y: auto;
    scrollbar-gutter: stable;
}
.diluents{
    height: 35%;
}
.materials{
    height: 50%;
}
.blue-stripe{
    margin: 0 10px 0 15px;
    border-radius: 8px;
    background: #00a3ff;
    color: white;
    height: calc(5% - 10px);
    width: calc(100% - 40px);
    padding: 5px 15px;

    font-weight: 600;
    font-size: 14px;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
/*ROUND BORDER FOR ANY CONTENT*/
.tkp-round-border {
    border-radius: 15px;
    overflow: hidden;
}
/*TABLE EXT CLASSES*/
.tkp-client-table__head{
    font-weight: 500;
}
.tkp-client-table__column {
    font-weight: 400;
}
@media print {
    button{
        display: none;
    }
}
@media screen and (max-width: 1440px) {
    .content__header{
        font-size: 14px;
    }
}
@media screen and (max-width: 1280px) {
    .content__header{
        font-size: 13px;

    }
}
@media screen and (max-width: 1024px) {
    .content__header{
        font-size: 12px;
        white-space: pre;
    }
    .btn{
        font-size: 11px;
    }
}