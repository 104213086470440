.kp-nav {
    justify-content: space-between;
}

.kp-nav__group {
    display: flex;
    align-items: center;
    gap: var(--gap-nav);
    flex-wrap: wrap;
}

.kp-nav__title {
    margin-bottom: 0;
    font-size: 24px;
    font-weight: 700;
    white-space: nowrap;
}
/*ALL BUTTONS*/
.kp-nav__btn {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 600;
    color: #ffffff;
    background-color: var(--color-blue);
    border: none;
    white-space: nowrap;
}
.kp-nav__btn-icon {
    width: 21px;
    height: 21px;
}
.icon-reverse {
    transform: scaleX(-1);
}
/*CLOSE BUTTON*/
.kp-nav__button-close {
    background-color: transparent;
    border: 1px solid var(--color-dark-gray);
    color: var(--color-dark-gray);
}
.kp-nav__icon-close {
    transform: rotate(90deg);
    color: var(--color-black);
}
/*REMOVE BUTTON*/
.kp-nav__button-remove {
    background-color: var(--color-red);
    border: none;
    color: #ffffff;
}
.kp-nav__icon-remove {
    width: 17px;
    height: 21px;
}
/*LINK BUTTON*/
.link-icon {
    width: 24px;
    height: 24px;
}
/*PDF BUTTON*/
.pdf-icon {
    width: 24px;
    height: 24px;
}