@layer frameworks, defaults, overrides;

@layer defaults {
    .remains-equipment {
        flex: 1 0 auto;
        --margin-top-eq: 20px;
        position: relative;
        margin-top: var(--margin-top-eq);
        padding: 0 20px;
        height: calc(100% - var(--tabs-row-height) - var(--margin-top-eq) - 30px);
        overflow: auto;
    }

    .remains-equipment__accordion {
        border-radius: 5px;
    }

    .remains-equipment__accordion-item:not(:first-child) {
        margin-top: 20px;
    }



    .remains-equipment__accordion-header {
        padding: 5px 10px;
        border-bottom: solid 1px var(--color-gray);
    }

    .accordion-border-none {
        border: none;
        outline: none;
    }

    .remains-equipment__accordion-item {
        border-top: solid 5px var(--color-light-blue);
        border-radius: 0;
    }

    .remains-equipment__accordion-body {
        padding: 5px 0 5px 10px;
    }

    .remains-equipment__accordion-input {
        border: 1px solid var(--color-light-gray);
        border-radius: 5px;
        padding: 3px;
        width: 100px;
        outline: none;
    }

    .remains-equipment__accordion-input:focus-visible {
        border: 1px solid var(--color-light-blue);
    }
}