.tkp-settings{
    width: 70vw;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 20px;
    gap: 10px;
}
.tkp-settings__items{
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.tkp-settings__default-warehouse{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
    flex: 1 1 30px;
    max-height: 30px;

}
.tkp-default-warehouse__label{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: fit-content;
    height: 100%;
    font-size: 12px;
    gap: 5px

}

.tkp-default-warehouse__select{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    width: 150px;
    height: 26px;
}
.tkp-settings__default-warehouse{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
    flex: 1 1 30px;
    max-height: 30px;
}
.tkp-settings__nds {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    gap: 5px;
}
.tkp-settings__switch {
    white-space: nowrap;
    display: flex;
    gap: 5px;
}
.tkp-settings__switch > .form-check-input:checked {
    border-color: var(--color-blue);
    background-color: var(--color-blue);
}
.input-price__container{
    width: 150px;
    height: 26px;
}
.tkp-settings__ext-settings {
    margin: 35px 0;
    display: flex;
    gap: 35px;
}
.tkp-settings__ext-settings-column {
    flex: 0.5 0 auto;
    display: flex;
    gap: 35px;
    flex-direction: column;
}