.courses {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.courses__add-btn-wrapper {
    display: flex;
    gap: 15px;
    justify-content: space-between;
}
.courses__list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: auto;
    margin: 20px 0;
    height: 100%;
}