.component__wrapper{
    padding: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
}
.component__information{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex: 6;
    max-height: 35%;
}
.component__information_block{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    flex: 1;
}
.component__block_text{
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
    max-height: 20px;
    height: 20px;
    white-space: pre;
}
.component__block_input{
    width: 200px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.input_name{
    width: 250px;
}
.component__block_title{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.component__block_item{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
    height: 25px;
}
.component__block_label{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: fit-content;
    height: 100%;
    white-space: pre;
}
.component__block_selector{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 250px;
    height: 100%;
}
.component-remains__wrapper{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    flex: 5;
    max-height: 50%;
}
.component-remains__title{
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex: 1;
    max-height: 10%;
}
.component-remains__container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex: 10;
    max-height: 90%;
}
.component__btns{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    flex: 1;
    gap: 10px;
    max-height: 5%;
}
.component__btn_add{
    border: none;
    background: #00b4e6;
    height: 40px;
    width: 250px;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    color: white;
    transition: all 0.15s linear;
}
.component__btn_add:active{
    transform: scale(0.98);
}
.guids-table__extensions{
    overflow: visible;
}