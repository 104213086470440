@import '../../../../assets/layers.css';

.system-modal {
    padding: 20px;
    max-width: 950px;
    width: auto;
}

@layer defaults {
    .modal-dialog {
        --bs-modal-width: 950px;
    }
}

.system-modal__body {
    display: grid;
    grid-template-columns: 4fr 3fr 1.5fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas:
    ". . ."
    ". . ."
    "medium . .";
    column-gap: 10px;
    row-gap: 17px;
    align-items: stretch;
}

.system-modal__body_medium {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-area: medium;
}

.system-modal__column {
    width: 100%;
}

.system-modal__controls {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 11px;
}

.system-modal__control {
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 0.375rem;
    border: none;
    background-color: var(--color-blue);
    color: var(--color-white);
    padding: 9px 18px;
    font-size: 12px;
    font-weight: 600;
}

.system-modal__control_close {
    background-color: var(--color-red);
}

.system-modal__control-icon {
    width: 20px;
    height: 20px;
}