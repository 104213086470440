.remains-toolbar {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 43px;
    width: 100%;
    width: 100%;
}

.remains-toolbar__btn {
    width: max-content;
    padding: 0 15px;
    white-space: nowrap;
    background-color: var(--color-light-blue);
}

.remains-toolbar__icon {
    --icon-size: 25px;
    width: var(--icon-size);
    height: var(--icon-size);
}

.remains-toolbar__settings {
    width: 38px;
    height: 38px;
    border: none;
    border-radius: 6px;
    background-color: var(--color-blue);
    color: var(--color-white);
    padding: 0;
}

@media (hover: hover) {
    .remains-toolbar__settings:hover {
        background-color: var(--color-blue-hover);
        color: var(--color-blue);
    }
}