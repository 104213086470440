.table-b__wrapper{
    background: transparent;

}
.table-b__header{
    position: sticky;
    scrollbar-gutter: stable;
    overflow: hidden;
    top: -1px;
    height: 40px;
    max-height: 40px;

    background: #FCFCFD;

    font-size: 14px;
    color: #6F767E;
    font-weight: 600;
    text-align: center;
}
.table-b__header_row{
    scrollbar-gutter: stable;
    overflow: hidden;
    height: 40px;
    max-height: 40px;

    background: #FCFCFD;

    border-bottom: 1px solid rgba(234, 236, 240, 1);


    font-size: 14px;
    color: #6F767E;
    font-weight: 600;
    text-align: center;
}
.table-b__header_col{
    overflow-x: hidden;

    color: rgba(102, 112, 133, 1);

    font-size: 13px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0;
    text-align: center;
}
.table-b__body{
    height: calc(100% - 40px);
    max-height: calc(100% - 40px);
    overflow: auto;
    scrollbar-gutter: stable;
    width: 100%;
}
.table-b__body_col{
    touch-action: none;
    overflow-x: hidden;

    color: rgba(102, 112, 133, 1);

    font-size: 13px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0;
    text-align: center;
}