@import url('../../../../assets/layers.css');

@layer defaults {
    .stretch-line {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: space-between;
        font-weight: 600;
        font-size: 13px;
        gap: 2px;
        background: #007fb5;
        border-radius: 0.37rem;
        overflow: hidden;
    }
}

