@import url('../../../../assets/layers.css');

@layer defaults {
    .stretch-line__item {
        padding: 0 10px;
        display: flex;
        flex: 1 1;
        flex-direction: column;
        justify-content: center;
        background: #009FE3;
        text-align: center;
        white-space: nowrap;
        border-radius: 0.37rem;
    }
}
