@layer reset, frameworks, defaults, patterns, components, utilities, overrides;

@import url('bootstrap/dist/css/bootstrap.min.css') layer(frameworks);

@font-face {
    font-family: 'Open Sans Regular';
    src: url("/src/shared/assets/OpenSans-Regular.ttf");
}
@font-face {
    font-family: 'Open Sans Bold';
    src: url("/src/shared/assets/OpenSans-Bold.ttf");
}
*, ::after, ::before {
    box-sizing: border-box;
}
html, body{
    font-family: "Open Sans Regular";
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    max-width: 100vw;

}
.flex{
    display: flex;
}
.center{
    justify-content: center;
    align-items: center;
}
.right{
    justify-content: flex-end;
}
.left{
    justify-content: flex-start;
}
.m-left-sm{
    margin-left: 20px;
}
.pointer{
    cursor: pointer;
}
.gap{
    gap: 10px;
}

#page{
    width: 100vw;
    height: 100vh;
    position: relative;
}

.icon{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 15px;
    min-width: 15px;
}
.icon i {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: all 0.2s linear;
    cursor: pointer;
}
.icon-action{
    cursor: pointer;
}
.icon i:hover{
    color: var(--color-blue);
}
.btns__slices{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    height: 100%;
    gap: 10px;
}

.cl-light-blue{
    color: var(--color-light-blue)!important;
}
.cl-light-green{
    color: var(--color-light-green)!important;
}
.cl-light-purple{
    color: var(--color-light-purple)!important;
}
.cl-yellow-muted{
    color: var(--color-yellow-muted)!important;
}
.cl-red-tomato{
    color: var(--color-red-tomato)!important;
}
.bg-light-blue{
    background: var(--color-light-blue)!important;
}
.bg-light-green{
    background: var(--color-light-green)!important;
}
.bg-light-purple{
    background: var(--color-light-purple)!important;
}
.bg-yellow-muted{
    background: var(--color-yellow-muted)!important;
}
.bg-red-tomato{
    background: var(--color-red-tomato)!important;
}
.button-popover{
    cursor: pointer;
}
.popover__btn{
    padding: 5px 10px;
    color: #6C7275;
    cursor: pointer;
    gap: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: calc(100% - 20px);
    height: fit-content;
    transition: all 0.25s linear;
    border-radius: 6px;
}
.popover__btn:hover{
    background: #E8ECEF80;
    color: black;

}
html::-webkit-scrollbar{
    width: 5px;
    border-radius: 15px;
}
html::-webkit-scrollbar-track {
    border-radius: 15px;
}
html::-webkit-scrollbar-thumb {
    background: var(--color-light-gray)y;
    border-radius: 15px;
}
html div::-webkit-scrollbar{
    width: 5px;
    border-radius: 15px;
}
html div::-webkit-scrollbar-track {
    border-radius: 15px;
}
html div::-webkit-scrollbar-thumb {
    background: var(--color-light-gray);
    border-radius: 15px;
}
html main::-webkit-scrollbar{
    width: 5px;
    border-radius: 15px;
}
html main::-webkit-scrollbar-track {
    border-radius: 15px;
}
html main::-webkit-scrollbar-thumb {
    background: var(--color-light-gray);
    border-radius: 15px;
}

