.pagination {
    --size: 49px;
    display: flex;
    justify-content: center;
    background-color: transparent;
    border: 1px solid var(--color-blue);
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .2);
    width: 100%;
}

.pagination__control {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--size);
    height: var(--size);
    border: none;
    background-color: transparent;
    color: #8c8c8c;
    font-weight: 600;
}

.pagination__control:hover {
    background-color: #cedae6;
    color: var(--color-blue);
    border-bottom: 4px solid #cedae6;
}

.pagination__control_active {
    border-bottom: 4px solid var(--color-blue);
}
.pagination__control_active:hover {
    border-bottom: 4px solid var(--color-blue);
}