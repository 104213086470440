@import '../../../../assets/layers.css';

@layer overrides {
    .materials-tkp__field {
        width: 60px;
    }
}

.slide-tkp-body__wrapper{
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.slide-tkp__equipments{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 65px);
}
.slide-tkp__table{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: calc(100% - 35px);
    overflow: auto;
}
.materials-slide-tkp__stripe_info{
    border-radius: 6px;
    background: #CCCCCC;
    color: black;
    font-size: 13px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 15px;
    padding: 5px 10px;
    width: 100%;
    height: 25px;
}

.slide-tkp-price__span_in-price {
    color: #00fb3b;
}
.slide-tkp-price__span_out-price {
    color: #fb7378;
}

@media (max-width: 1643px) {
    .equipments-selector__item_remains {
        max-width: 300px;
        flex-wrap: wrap;
        justify-content: center;
    }

    .equipments-selector__remains_item {
        flex: 0 0 auto;
    }
}
