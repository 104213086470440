.input-course__fields {
    width: 100%;
    display: flex;
    gap: 15px;
    justify-content: space-between;
}
.input-course__fields-group {
    display: flex;
    gap: 15px;
}

.input-course__content {
    display: flex;
    gap: 20px;
    flex-direction: column;
}