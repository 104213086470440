.multi-field__textarea {
    width: 100%;
    height: 100%;
    border: none;
    resize: unset;
    background-color: transparent;
    color: var(--color-dark-gray);
    font-size: 13px;
}

.multi-field__textarea:focus-visible {
    outline: none;
}