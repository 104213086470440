.multi-field {
    display: flex;
    align-items: center;
    position: relative;
    padding: 11px 32px 11px 16px;
    width: 100%;
    height: 100%;
    border-radius: 0.375rem;
    box-shadow: 2px 2px 4px var(--color-light-gray);
    font-size: 14px;
}

@media (hover: hover) {
    .multi-field:hover {
        background-color: var(--color-blue-hover);
        color: var(--color-blue);
    }

    .multi-field.readonly:hover {
        background-color: transparent;
        color: var(--color-black);
    }
}

.multi-field__title {
    position: absolute;
    top: -13px;
    color: var(--color-dark-gray);
    z-index: 10;
    font-size: 12px;
}

.multi-field__pen-icon {
    position: absolute;
    bottom: 50%;
    right: 10px;
    width: 20px;
    height: 20px;
    transform: translateY(50%);
}

.multi-field__pen-icon_clickable {
    cursor: pointer;
}