.remains-line {
    border-top: none;
    border-bottom: 1px solid var(--color-gray);
    border-left: none;
    border-right: none;
}

.remains-line__param {
    display: flex;
}

.remains-line__header {
    padding: 10px 0;
    width: 140px;
    flex-shrink: 0;
}


.remains-line__data {
    width: 100%;
    display: flex;
    align-items: center;
    margin-left: 10px;
    gap: 20px;
}

.remains-line__btn {
    background-color: var(--color-blue);
}

.remains-line__footer {
    padding-top: 10px;
    display: flex;
    gap: 10px;
}

.remains-line__select{
    background: var(--color-blue);
    border-radius: 8px;
    color: white;
    padding: 0 10px;

}
.remains-line__selector{
    width: 100%;
    height: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;
}

.remains-line__footer > button {
    width: auto;
    padding: 0 5px;
}

.remains-line__input {
    border: solid 1px var(--color-light-gray);
    border-radius: 5px;
    padding: 2px 5px;
    outline: none;
}

.remains-line__input:focus-visible {
    border-color: var(--color-light-blue);
}