.user-detail__wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
}
.user-detail__header{
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.user-detail__body{
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    overflow: auto;
}
.user-detail_title{
    padding: 0;
    margin: 0;
    width: 100%;
    text-align: left;
}

.button-extension{
    width: 250px;
    height: 35px;
}