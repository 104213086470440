@import url('../../../../assets/layers.css');

.total-bar-tkp {
    width: 100%;
}
.total-bar-tkp__items{
    border-radius: 8px;
    background: #007fb5;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 2px;
    font-weight: 600;
    font-size: 13px;
    color: #F5F5F5;
}
.total-bar-tkp__item {
    height: 65px;
    flex: 1 1;
    padding: 0 10px;
    text-align: center;
    background: #009Fe3;
    border-radius: 0.37rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.total-bar-tkp__item p {
    white-space: nowrap;
    margin: 0;
}
@layer overrides {
    .stretch-line__item-custom {
        height: 65px;
    }
}
