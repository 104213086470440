@layer defaults {
    .detail__body {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        gap: 10px;
        overflow: auto;
    }
}