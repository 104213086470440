.price-course {
    padding: 5px;
    background-color: white;
    display: flex;
    gap: 20px;
    align-items: flex-end;
    flex-wrap: wrap;
    border-radius: 5px;
}

.price-course__default-unit {
    display: flex;
    align-items: flex-end;
    gap: 10px;
}