.remains__tabs-row {
    --padding-tab-left: 15px;
    padding: 0 var(--padding-tab-left);
    display: flex;
    align-items: center;
    gap: 20px;
    height: var(--tabs-row-height);
}

.remains__button-tab {
    width: max-content;
    border-radius: 10px;
    border: solid 1px var(--color-light-gray);
}

.tab-button-remains_active {
    border: solid 1px var(--color-light-blue);
}

.remains {
    --tabs-row-height: 55px;
    --toolbar-height: 43px;
    height: 100%;
    display: flex;
}

.remains__props {
    width: 300px;
    padding: 10px 0 10px 0;
    margin: 10px;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--color-blue);
    border-radius: 5px;
    overflow: hidden;
}

.remains__content {
    width: 100%;
}

.remains__body {
    display: flex;
    flex-direction: column;
    --padding-body: 10px;
    width: calc(100% - var(--padding-body));
    height: calc(100% - 55px - var(--padding-body));
    padding: var(--padding-body) 0 0 var(--padding-body);
}

.remains__usd > label {
    white-space: nowrap;
}

.remains__usd-input {
    border: 1px solid var(--color-light-gray);
    border-radius: 5px;
    padding: 4px;
    outline: none;
    width: 60px;
}

.remains__usd-input:focus-visible {
    border-color: var(--color-light-blue);
}

.remains__sw > input:checked {
    background-color: var(--color-blue);
    border-color: var(--color-blue);
}