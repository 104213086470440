@import '../../../../assets/layers.css';

@layer overrides {
    .tkp-template-modal {
        --bs-modal-width: 500px;
    }

    .tkp-template-modal__input {
        padding: 5px 10px;
        width: 100%;
        box-shadow: 1px 2px 5px var(--color-blue-hover);
    }
}

.tkp-template-modal__controls {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 15px;
}

.tkp-template-modal__btn {
    padding: 5px 15px;
    border-radius: 0.375rem;
    border: none;
    background-color: var(--color-blue);
    color: var(--color-white);
}

.tkp-template-modal__btn[disabled] {
    background-color: var(--color-gray);
}

.tkp-template-modal__btn_cancel {
    background-color: var(--color-red);
}