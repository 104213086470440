#remains-checkbox {
    padding: 2px 10px;
    cursor: pointer;
    display: flex;
    gap: 10px;
}
#remains-checkbox__input {
    width: 17px;
    height: 17px;
    cursor: inherit;
    margin-top: 3px;
}
#remains-checkbox__label {
    cursor: inherit;
}
#remains-checkbox__input:checked {
    background-color: var(--color-blue);
    border-color: var(--color-blue);
}
@media (hover: hover) {
    #remains-checkbox:hover {
        background-color: var(--color-blue-hover);
        color: var(--color-blue);
    }
}