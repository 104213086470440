.crm__wrapper{
    width: 100%;
    height: 100%;
}
.header{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.header ul{
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.app-content-crm{
    height: 100%;
    width: 100%;
}
.app-content-crm::-webkit-scrollbar{
    width: 5px;
    border-radius: 15px;
}
.app-content-crm::-webkit-scrollbar-track {
    border-radius: 15px;
}
.app-content-crm::-webkit-scrollbar-thumb {
    background: var(--color-light-gray)y;
    border-radius: 15px;
}
.app-content-crm div::-webkit-scrollbar{
    width: 5px;
    border-radius: 15px;
}
.app-content-crm div::-webkit-scrollbar-track {
    border-radius: 15px;
}
.app-content-crm div::-webkit-scrollbar-thumb {
    background: var(--color-light-gray);
    border-radius: 15px;
}



