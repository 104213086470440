/*!*ACCORDION*!*/
/*#nomenclature-materials__item {*/
/*    border-top: none;*/
/*    border-bottom: 1px solid var(--color-gray);*/
/*    border-left: none;*/
/*    border-right: none;*/
/*}*/

/*#nomenclature-materials__header {*/
/*    padding: 10px 0;*/
/*    flex-shrink: 0;*/
/*    border-bottom: 1px solid var(--color-gray);*/
/*}*/

/*#nomenclature-materials__body {*/
/*    padding: 10px 0;*/
/*}*/

/*!*element-nomenclature-materials*!*/
/*.element-nomenclature-materials {*/
/*    padding: 5px 10px;*/
/*    display: flex;*/
/*    gap: 10px;*/
/*    align-items: center;*/
/*    color: var(--color-blue);*/
/*    cursor: pointer;*/
/*}*/

/*@media (hover:hover) {*/
/*    .element-nomenclature-materials:hover {*/
/*        background-color: var(--color-blue-hover);*/
/*    }*/
/*}*/

/*.element-nomenclature-materials__icon {*/
/*    width: 30px;*/
/*    height: 30px;*/
/*}*/

/*.element-nomenclature-materials__name {*/
/*    color: var(--color-black);*/
/*}*/

