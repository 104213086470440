.kanban__wrapper{
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
}
.kanban__lanes{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    overflow: hidden;
    height: 100%;
    width: 100%;
}
.horizontal-scroll{
    position: absolute;
    opacity: 0.5;
    background: lightgrey;
    width: 100px;
    height: 100px;
    object-fit: cover;
    padding: 10px;
}
.horizontal-scroll-left{
    top: 50%;
    transform: translateY(-50%);
    border-bottom-right-radius: 50%;
    border-top-right-radius: 50%;
}
.horizontal-scroll-right{
    right: 0;
    top: 50%;
    transform: translateY(50%);
    border-bottom-right-radius: 50%;
    border-top-right-radius: 50%;
    rotate: 180deg;
}
.kanban__lane{

    flex: 0 0 auto;
    height: 100%;
    padding: 15px 10px;
    border-radius: 1rem;
    width: 287px;
    background: white;
}
.kanban__lane_label{
    height: 40px;
    padding: 0 15px;
}

.label-lane {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
}

.label-lane__add-icon {
    transform: rotate(45deg);
    cursor: pointer;
    color: var(--color-dark-gray);
}

@media (hover: hover) {
    .label-lane__add-icon:hover {
        color: var(--color-black);
    }
}

.label-lane__group {
    display: flex;
    align-items: center;
    gap: 5px;
}

.label-lane__color {
    width: 13px;
    height: 13px;
    border-radius: 50%;
}

.label-lane__text {
    font-size: 15px;
}

.label-lane__text_gray {
    color: var(--color-light-gray);
}

.kanban__items{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 20px;
    overflow: auto;
    border-radius: 6px;
    min-height: 300px;
    max-height: calc(100% - 40px);
}
.kanban__item{
    padding: 10px;
    width: 100%;
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    color: #221C1D;
    font-weight: 600;
    font-size: 14px;
    cursor: grab;
}