.remains-filters {
    flex: 1 0;
    height: 100%;
}

.remains-filters__form {
    display: flex;
    height: 100%;
    flex-direction: column;
}

.remains-filters__wrapper {
    flex: 1 0 auto;
    height: calc(100% - 100px);
    overflow: auto;
}

.remains-filters__none-accordion {
    border-top: solid 1px var(--color-gray);
}

.remains-filters__controls {
    margin-top: 10px;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.remains-filters__controls > button {
    border-radius: 5px;
    margin: 0 5px;
    height: 40px;
}

.remains-filters__submit-button {
    border: 1px solid var(--color-blue);
    background-color: var(--color-blue);
    color: var(--color-white);
}

.remains-filters__clear-button {
    border: 1px solid var(--color-gray);
    background-color: var(--color-light-gray);
}

.filters-inputs__inputs {
    display: flex;
    gap: 10px;
    padding: 0 10px 10px 10px;
}

.filter-inputs__label {
    padding: 0 10px;
}