.client-detail__wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.client-detail__header{
    height: 100%;
    width: 20%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
}
.client-detail__body{
    height: 100%;
    width: 80%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
    overflow: auto;
}
.client-detail__information{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.client-detail_title{
    padding: 0;
    margin: 0;
    width: 100%;

    color: #141718;

    font-size: 28px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -0.01em;
    text-align: left;

}
.client-detail__select{
    width: 250px;
    height: 25px;
}
.button-extension_tab{
    width: 200px;
    height: 35px;
}
.button-extension_delete{
    width: 200px;
    height: 35px;
    opacity: 1;
}
.input-extension{
    width: 250px;
    height: 25px;
}
.icon-extension{
    flex: 0 0 25px;
    width: 25px;
    height: 25px;
}