.filter-input {
        border: 1px solid var(--color-gray);
        border-radius: 5px;
        height: 30px;
        overflow: hidden;
        display: flex;
        align-items: center;
}

.filter-input__input {
        border: none;
        outline: none;
        height: 100%;
        padding: 5px 0 5px 10px;
        width: calc(100% - 20px);
        line-height: normal;
}

.filter-input__close-button {
        --btn-size: 20px;
        border: none;
        background: none;
        height: 100%;
        width: var(--btn-size);
        padding: 0 0 2px 0;
        color: var(--color-dark-gray);
}

.filter-input__close-button:hover {
        color: var(--color-black)
}

.filter-input__close-icon {
        width: var(--btn-size);
        height: var(--btn-size);
}