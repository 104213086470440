@layer defaults {
    .button-tab__wrapper {
        padding: 0 15px;
        cursor: pointer;
        position: relative;
        z-index: 200;
        height: 35px;
        color: var(--color-black);
        background: transparent;
        border: none;
        border-radius: 0.375rem;
        overflow: hidden;
        opacity: 0.7;

        display: flex;
        justify-content: flex-start;
        align-items: center;

        white-space: pre;
    }

    .button-tab__active {
        opacity: 1;
        color: var(--color-blue);
    }

    .button-tab {
        color: inherit;

        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.02em;

        background: transparent;
        cursor: pointer;
        position: relative;
        z-index: 200;
        height: 100%;
        width: 100%;
        max-width: 100%;
        border: none;
        text-align: left;
        transition: color 0.05s linear;
    }

    .button-tab:hover {
        color: #1C87E9;
    }

    .button-tab:active {
        color: #00a3ff;

    }
}