#remains__accordion-item {
    border: none;
}

#remains__accordion-header {
    box-sizing: border-box;
}

#remains__accordion-body {
    padding: 0;
    margin-bottom: 10px;
}