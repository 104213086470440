@layer defaults {
    .tabs__block {
        height: 100%;
        max-width: 20%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        gap: 10px;
    }
}

