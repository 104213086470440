.clear-btn {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px var(--color-red) dotted;
    color: var(--color-red);
    background: none;
    cursor: default;
    opacity: 0;
    transition: opacity 0.2s;
    margin: 0 10px;
}

.clear-btn_active {
    cursor: pointer;
    opacity: 1;
}