.blocks-list__wrapper{
    width: 100%;
    height: 100%;
}
.blocks-list__header{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 40px;
}
.blocks-list__items{
    width: 100%;
    height: calc(100% - 40px);
    overflow: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
}
.blocks-list__item{
    width: 100%;
}
.blocks-list__item_header{
    padding: 5px 10px!important;
    width: calc(100% - 20px);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.blocks-list__item_children{
    width: calc(100% - 20px);
}
.custom-toggle{
    transition: all 0.25s linear;
}
.custom-toggle-rotate{
    transform: rotate(180deg);
}