.selector-component__wrapper{
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}
.selector-component__header{
    flex: 1;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 10px;
}
.selector-component__list{
    flex: 10;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    display: flex;
    gap: 10px;
    overflow: auto;

}
.selector-component__item{
    height: 30px;
    width: calc(100% - 20px);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    transition: all 0.15s linear;
    background: #CCCCCC;
}
.selector-component__item:hover{
    background: #00b4e6;
}