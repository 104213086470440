.fixed-loader-container{
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 50px;
    font-weight: 700;
}