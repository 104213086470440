@layer reset, frameworks, defaults, patterns, components, utilities, overrides;

@layer defaults {
    .accordion-toggle {
        box-sizing: border-box !important;
    }
}

.accordion-toggle {
    padding: 0 10px;
    --accordion-toggle-icon-size: 16px;
    background-color: transparent;
    border: none;
    box-sizing: border-box !important;
    width: 100%;
    text-align: left;
    display: flex;
    gap: 10px;
    align-items: center;
}
.accordion-toggle__icon {
    transition: transform 0.3s ease 0s;
}
.accordion-toggle.collapsed > .accordion-toggle__icon {
    transform: rotate(-180deg);
}

@media (hover:hover) {
    .accordion-toggle:hover {
        background-color: var(--color-blue-hover);
        color: var(--color-blue);
    }
}