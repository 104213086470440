.tkp-list {
    height: 100%;
    width: 100%;
}
.tkp-list__wrapper_n{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}
.tkp-list__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
}
.tkp-card__wrapper{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.tkp-card__priority{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 40px;
    opacity: 0.5;
    text-align: center;
    padding: 2px;
    font-size: 12px;
}
.tkp__button {
    flex: 0 0 auto;
    height: 38px;
    width: 184px;
    padding: 4px 17px;
    background-color: var(--color-blue);
    color: var(--color-white);
    border: none;
    border-radius: 0.375rem;
    display: flex;
    align-items: center;
}

.tkp-card__info{
    display: flex;
    flex-direction: column;
}
.tkp-card__footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--color-dark-gray);
    font-size: 13px;
    gap: 5px;
}
.tkp-card__avatar{
    overflow: hidden;
    border-radius: 50%;
    width: 18px;
    height: 18px;
}

.tkp-card__container {
    display: flex;
    justify-content: space-between;
}

.tkp-card__created-time {
    font-size: 13px;
    color: var(--color-dark-gray);
}

.tkp-card__name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.tkp-card__creds {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 13px;
    color: var(--color-dark-gray);
}

.tkp-card__footer-price {
    display: flex;
    align-items: center;
    gap: 5px;
}