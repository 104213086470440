.modal-update-bxinfo__wrapper{
    padding: 20px;
    width: 100%;
    height: 30vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
}
.modal-update-bxinfo__container{
    height: 85%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}
.modal-update-bxinfo__block{
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
}
.modal-update-bxinfo__btns{
    height: 15%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.modal-update-bxinfo__title{
    padding: 0;
    margin: 0;
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.modal-update-bxinfo__info{
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.modal-update-bxinfo__label{
    font-weight: 600;
}
.modal-update-bxinfo__text{

}