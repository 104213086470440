.entity-selector__items{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

}.entity-selector__item{
    padding: 0 15px;
    width: calc(100% - 30px);
    height: 25px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: all 0.2s linear;
    border-radius: 6px;
    cursor: pointer;
    gap: 10px;
}
.entity-selector__item:hover{
    background: #00b4e6;
    color: white;
}
.entity-selector__item_select{
    background: #00b4e6;
    color: white;
}
.entity-selector__name{
    font-weight: 600;
    font-size: 16px;
}

.input-extension{
    width: 250px;
    height: 35px;
}