.guids__wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
}
.guids__wrapper_padding{
    padding: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
}