.component-materials {
    padding: 5px 10px;
    display: flex;
    gap: 10px;
    align-items: center;
    color: var(--color-blue);
    cursor: pointer;
}

@media (hover:hover) {
    .component-materials:hover {
        background-color: var(--color-blue-hover);
    }
}

.component-materials__icon {
    width: 30px;
    height: 30px;
}

.component-materials__name {
    color: var(--color-black);
}