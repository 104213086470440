@import "../../../assets/layers.css";

@layer defaults {
    .detail__card_title{
        padding: 0;
        margin: 0;
        width: fit-content;

        color: #141718;

        white-space: pre;
        font-size: 24px;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: -0.01em;
        text-align: left;
    }
    @media screen and (max-width: 1660px) {
        .detail__card_title{
            font-size: 22px;
            line-height: 36px;
        }
    }
    @media screen and (max-width: 1440px) {
        .detail__card_title{
            font-size: 20px;
            line-height: 32px;
        }
    }
    @media screen and (max-width: 1280px) {
        .detail__card_title{
            font-size: 16px;
            line-height: 26px;
        }
    }
    @media screen and (max-width: 1024px) {
        .detail__card_title{
            font-size: 14px;
            line-height: 20px;
        }
    }
}
