.default-sidebar__wrapper{
    flex: 0 0 320px;
    position: relative;
    height: 100%;
    background: #141718;
    color: white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}
.hide__menu{
    flex: 0 0 80px;
}
.gradient{
    opacity: 0.01;
    border-radius: 50%;
    z-index: 150;
    left: -200px;
    top: -150px;
    width: 500px;
    height: 500px;
    position: absolute;
    background: conic-gradient(from 180deg at 50% 50%, #1C87E9 -41.44deg, #0047FF 138.22deg, #1C87E9 318.56deg, #0047FF 498.22deg);
    box-shadow:
            0 0 7px #fff,
            0 0 10px #fff,
            0 0 21px #fff,
            0 0 42px #00b4e6,
            0 0 82px #00b4e6,
            0 0 92px #00b4e6,
            0 0 102px #00b4e6,
            0 0 151px #00b4e6;
}
.hide__gradient {
    width: 80px;
    left: 0;
}
.default-sidebar__body{
    margin: 25px 25px 0 0;
    overflow-x: visible;
    z-index: 250;
    width: calc(100% - 25px);
    height: calc(80% - 25px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
    --header-height: 45px;
}
.hide__body{
    margin: 0;
    width: 100%;
    height: 80%;
}
.hide__header{
    margin: 15px 0 0 0!important;
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
    width: 100%!important;
}
.hide__item{
    margin: 0!important;
    display: flex;
    align-items: center;
    justify-content: center!important;
}
.default-sidebar__header{
    padding-left: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    height: var(--header-height);
    width: 100%;
}
.logout__container{
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.logout-icon{
    opacity: 0.7;
    transition: all 0.5s linear;

}
.logout-icon-rotate{
    transform: rotate(180deg);
}
.logout__container:hover .logout-icon{
    opacity: 1;
    color: #e15e5a;
}
.default-sidebar__logo{
    width: 170px;
    height: 100%;
}
.default-sidebar__menu{
    overflow-x: visible;
    overflow-y: auto;
    width: 100%;
    height: calc(100% - var(--header-height));
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 5px;
}
.default-sidebar__item_container{
    border-radius: 10px;
    width: calc(100% - 25px);
    height: fit-content;
    margin: 0 0 0 25px;
    overflow-x: visible;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
}
.default-sidebar__item_container:has(.default-sidebar__item_active){
   /* background: linear-gradient(270deg, rgba(255, 255, 255, 0.01) 50%, rgba(80, 62, 110, 0.01) 100%);*/
}
.default-sidebar__item{
    padding: 10px;
    border-radius: 10px;
    text-decoration: none;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    cursor: pointer;
    transition: all 0.09s linear;
}
.default-sidebar__subitem{
    overflow-x: visible;
    text-decoration: none;
    border-radius: 10px;
    padding: 10px;
    margin-left: 25px;
    width: calc(100% - 45px);
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    cursor: pointer;
    transition: all 0.09s linear;
}
.default-sidebar__item_active{
    background: linear-gradient(270deg, #323337 50%, rgba(80, 62, 110, 0.29) 100%);
}
.default-sidebar__subitem_active{
    background: linear-gradient(270deg, #323337 50%, rgba(80, 62, 110, 0.29) 100%);

}
.default-sidebar__item_active .default-sidebar__text{
    color: white;
}
.default-sidebar__item_active .default-sidebar__color{
    --color-neon: '';
    box-shadow:
            0 0 1px var(--color-neon),
            0 0 1px var(--color-neon),
            0 0 1px var(--color-neon),
            0 0 1px var(--color-neon),
            0 0 1px var(--color-neon),
            0 0 1px var(--color-neon),
            0 0 41px var(--color-neon),
            0 0 45px var(--color-neon);
}
.default-sidebar__item:hover{
    background: linear-gradient(270deg, #323337 50%, rgba(80, 62, 110, 0.29) 100%);

}
.default-sidebar__subitem:hover{
    background: linear-gradient(270deg, #323337 50%, rgba(80, 62, 110, 0.29) 100%);

}
.default-sidebar__item:hover .default-sidebar__text{
    color: white;

}
.default-sidebar__text{
    overflow-x: visible;
    text-decoration: none;
    color: #E8ECEFBF;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    height: 100%;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.8px;
    text-align: left;


}
.default-sidebar__crumbs{
    overflow-x: visible;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 600;
    width: 24px;
    height: 24px;
    background: #232627;
    color: #6C7275;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;

}
.default-sidebar__color{
    text-decoration: none;
    cursor: pointer;
    border-radius: 4px;
    background: #e15e5a;
    width: 14px;
    height: 14px;

}
.default-sidebar__footer{
    padding: 0 20px 25px 20px;
    width: 100%;
    height: 20%;
}
.user__profile{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 10px;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: rgba(35, 38, 39, 1);
    box-shadow: 0 20px 24px 0 rgba(0, 0, 0, 0.5);

}
.user__block{
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}
.user__avatar{
    overflow: hidden;
    border-radius: 50%;
    width: 40px;
    height: 40px;
}
.user-img__avatar_sidebar{
    width: 100%;
    height: 100%;
}
.user__info{
    flex: 1.5;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.user__name{
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.2px;
    text-align: left;
    color: rgba(254, 254, 254, 1);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.user_email{
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.2px;
    text-align: left;
    color: rgba(232, 236, 239, 0.5);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.user__other{
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
}
.user__role{
    background: rgba(63, 221, 120, 1);
    border-radius: 8px;
    height: 24px;
    width: 68px;
    padding: 0 12px 0 12px;

    color: rgba(20, 23, 24, 1);
    font-size: 12px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.2px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

}
.button__profile{
    flex: 0 0 auto;
    width: 100%;
    height: 40px;
    background: transparent!important;
    border: 2px solid rgba(52, 56, 57, 1)!important;
}