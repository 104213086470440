.default-modal__wrapper{
    position: fixed;
    padding: 20px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    width: 100vw;

    z-index: 9999;
    display: none;
}
.default-modal__content{
    min-height: 50px;
    min-width: 250px;
    background: white;
    border-radius: 10px;
    box-shadow: 4px 4px 25px 0 rgba(34, 60, 80, 0.2);
}
.default-modal__active{
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}