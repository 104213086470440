.contacts-table__wrapper{
    padding: 20px;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
}
.contacts-table__container{
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contacts-table__btns{
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.check-modal-phone__wrapper{
    padding: 20px;
    width: 25vw;
    height: 25vh;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    flex-direction: column;
}
.check-modal-phone__title{
    padding: 0;
    margin: 0;
}