.login__wrapper{
    background: rgba(20, 23, 24, 1);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
    height: 100%;
    padding: 20px;
}
.login__sidebar{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 50px 20px 0 30px;
    width: fit-content;
    height: 100%;
    gap: 10px;
}
.login__sidebar_title{
    white-space: pre;
    width: 100%;
    height: fit-content;
    text-align: left;
    color: white;
    padding: 0;
    margin: 0;

    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: -0.02em;

}
.login__sidebar_subtitle{
    white-space: pre;
    width: 100%;
    height: fit-content;
    text-align: left;
    color: white;
    padding: 0;
    margin: 0;
    color: rgba(232, 236, 239, 0.5);

    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: -0.02em;

}
.login__container{
    position: relative;
    z-index: 250;
    background: white;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 25px;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    transition: all 0.5s linear;
}
.login__container_red {
    box-shadow: inset 0 0 15px var(--color-red);
}
.login__img{
    width: 250px;
    height: 55px;
}
.login__header{
    gap: 10px;
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}
.login__tabs{
    width: fit-content;
    padding: 5px;
    height: fit-content;
    background: rgba(243, 245, 247, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 6px;
}
.login__tab{
    height: max-content;
    cursor: pointer;
    text-align: center;
    width: 250px;
    white-space: pre;
    color: rgba(108, 114, 117, 1);
    background: transparent;
    padding: 5px;
    border-radius: 6px;
}
.login__tab_active{
    color: black;
    background: white;
}
.login__inputs{
    width: 540px;
    height: fit-content;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}
.submit-login_button .btn .btn-primary{
    width: 540px;
    background: rgba(0, 160, 230, 1);
    border-radius: 6px;
    padding: 0;
    height: 40px;
}
.login__recovery{
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-align: left;
    color: rgba(0, 160, 230, 1);
}
.login__icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 100%;
}
.login__label{
    width: fit-content;
    height: fit-content;
    text-align: left;
    font-size: 15px;
    opacity: 0.9;
}
.login__btns{
    width: 100%;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.input-extension-login{
    flex: 1;
    height: 100%;
    background: transparent;
    text-align: left;
    font-size: 16px;

    border: none;
}
.input-extension-login{
    flex: 1;
    height: 100%;
    background: transparent;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.01em;


    border: none!important;
}
.input-extension-login::placeholder{
    text-align: left;
}
.input-extension-incorrect {
    color: var(--color-red)
}



.login__svg{
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;
}

h3{

}