.equipment__header {
    margin: 20px 0;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;
    text-align: center;
}
.equipment__fields {
    margin-top: 20px;
    display: flex;
    width: 100%;
    padding-bottom: 10px;
}
.equipment__color {
    display: inline-block;
    width: 1em;
    height: 1em;
}