.tkp__label{
    font-size: 13px;
    font-weight: 600;
    font-style: normal;
    white-space: pre;
    overflow-x: hidden;
}
@media screen and (max-width: 1440px) {
    .tkp__label{
        font-size: 13px;
    }
}
@media screen and (max-width: 1280px) {
    .tkp__label{
        font-size: 12px;
    }
}
@media screen and (max-width: 1024px) {
    .tkp__label{
        font-size: 10px;
    }
}