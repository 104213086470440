.tkp-symmary-material__container{
    width: 100%;
    height: 100% ;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}
.tkp-symmary-label__material{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 15%;
    height: 100%;

    font-size: 12px;
    font-weight: 600;
    color: var(--color-dark-gray);
}
.tkp-summary-material__container_label{
    padding: 0 10px;
    gap: 10px;
    height: 100%;
    font-size: 13px;
    white-space: pre;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 6px;
    position: relative;
    overflow: hidden;

}
@media screen and (max-width: 1440px) {

}
@media screen and (max-width: 1280px) {

}
@media screen and (max-width: 1024px) {
    .tkp-summary-material__container_label{
        font-size: 11px;
    }
}