#dropdown {}

#dropdown__toggle {
    min-width: 150px;
    max-width: 300px;
    width: auto;
    border: 2px solid var(--color-light-gray);
    border-radius: 0.375rem;
    background-color: #ffffff;
    color: var(--color-dark-gray);
    font-size: 16px;
    /*font-weight: 600;*/
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.dropdown__toggle-text {
    flex: 0 1 auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

#dropdown__item {
    padding: 5px 10px;
    color: var(--color-dark-gray);
    font-size: 16px;
}

@media (hover: hover) {
    #dropdown__item:hover {
        color: var(--color-blue);
        background: var(--color-blue-hover);
    }
}

#dropdown__item.active {
    border-bottom: 5px var(--color-blue) solid;
    background-color: transparent;
}

#dropdown__item-input:focus {
    box-shadow: none;
    border: var(--color-blue) 1px solid;
}