.breadcrumbs {
    border-radius: 7px;
    color: var(--color-dark-gray);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
}

.breadcrumbs__home {
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.breadcrumbs__separator {
    font-size: 16px;
}

@media (hover: hover) {
    .breadcrumbs__home:hover {
        color: var(--color-gray);
    }

    .breadcrumbs__item-title:hover {
        color: var(--color-gray);
    }
}

.breadcrumbs__item {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
}